<template>
  
  <div></div>

</template>
<script>
// import {inject} from "vue";
// import request from "@/utils/request";
import { getUrlParams } from "@/utils";

import axios from 'axios'
const httpRequest = axios.create({
  withCredentials: false
})

export default {
  name: 'BookPrinter',
  components: {
    
  },


  setup() {
    // 指示当前页的名称，以便使菜单显示高亮；
    // const pageIndicator = inject("pageIndicator");
    // pageIndicator.currentPageName="service";
    // console.log("inject pageIndicator[service pdf books] ...", pageIndicator);
  }, 


  data() {
    return {
      

    }
  },

  methods: {


    loadAndPrintPDF(){
      const fileId = getUrlParams('pdf');
      const url = `/api/ffs/file/${fileId}`;

      // window.location = url;
      // window.focus();
      // window.print();

      httpRequest({
        method:"get",
        url:url,
        responseType: "blob",
      }).then((resp) =>{
        let blob = new Blob([resp.data], {type:"application/pdf"});
        const blobUrl = URL.createObjectURL(blob);
        // window.location.replace(blobUrl);

       const iframe =  document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobUrl;
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
       
        
        // alert("window download...");
        // setTimeout(() => {
        //   window.location = blobUrl;
        //   window.onload(()=>{
        //     console.log("window loaded...")
        //     alert("window loaded...")
        //     window.print();
        //   });
        // }, 200);

      }).catch(error => {
        console.error("ERROR ! -- ", error);
      });
    },
  },

  computed: {
    pdfUrl() {
      return `/api/ffs/file/${this.pdfData?.pdf?.id}`;
    }
  },
  mounted() {
    this.loadAndPrintPDF();
  },
}
</script>
<style lang="less" scoped>

</style>